exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-diccionario-tsx": () => import("./../../../src/pages/diccionario.tsx" /* webpackChunkName: "component---src-pages-diccionario-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kodi-functions-jsx": () => import("./../../../src/pages/kodi/functions.jsx" /* webpackChunkName: "component---src-pages-kodi-functions-jsx" */),
  "component---src-pages-kodi-instalation-jsx": () => import("./../../../src/pages/kodi/instalation.jsx" /* webpackChunkName: "component---src-pages-kodi-instalation-jsx" */),
  "component---src-pages-kodi-jsx": () => import("./../../../src/pages/kodi.jsx" /* webpackChunkName: "component---src-pages-kodi-jsx" */),
  "component---src-pages-kodi-operators-jsx": () => import("./../../../src/pages/kodi/operators.jsx" /* webpackChunkName: "component---src-pages-kodi-operators-jsx" */),
  "component---src-pages-kodi-statements-jsx": () => import("./../../../src/pages/kodi/statements.jsx" /* webpackChunkName: "component---src-pages-kodi-statements-jsx" */),
  "component---src-pages-kodi-utilities-jsx": () => import("./../../../src/pages/kodi/utilities.jsx" /* webpackChunkName: "component---src-pages-kodi-utilities-jsx" */),
  "component---src-pages-kodi-variables-jsx": () => import("./../../../src/pages/kodi/variables.jsx" /* webpackChunkName: "component---src-pages-kodi-variables-jsx" */),
  "component---src-pages-skills-tsx": () => import("./../../../src/pages/skills.tsx" /* webpackChunkName: "component---src-pages-skills-tsx" */)
}

